export class ProductHelper {
  public static checkIfIsInFeeds(productFeeds: number[], configFeeds: number[]): boolean {
    const set = new Set(productFeeds);
    for (let num of configFeeds) {
      if (set.has(num)) {
        return true;
      }
    }
    return false;
  }

  public static processESChildren(child: any, colorReferences: Record<string, string> | undefined): any {
    // Structure for v4ApiResponse.metaproduct children
    const priceEntry = child.prices.find((price: any) => price.bundle_object_id === '-1');
    const price = priceEntry.price.toString();
    const crossedPrice =
      priceEntry.crossed_price > priceEntry.price && priceEntry.crossed_price > 0 ? priceEntry.crossed_price.toString() : '';
    return {
      label: child.edition,
      id: child.productID,
      hasStock: Number(child.stock) > 0,
      stockPolicy: child.ostock,
      price: price ? price : '',
      crossedPrice: crossedPrice ? crossedPrice : '',
      color: child.platform,
      hexcode: colorReferences?.[child.platform] || '',
    };
  }

  public static processV4Children(child: any): any {
    // Structure for specificV4 options
    const platformField = child.custom_fields.find((field: any) => field.type === 'CF_PLATFORM');
    const hexcodeField = child.custom_fields.find((field: any) => field.type === 'CF_HEXCODE');
    return {
      label: child.value,
      id: child.productID,
      hasStock: child.has_stock,
      stockPolicy: child.stock_policy,
      price: child.price.toString() || '',
      color: platformField ? platformField.value : '',
      hexcode: hexcodeField ? hexcodeField.value : '',
    };
  }
}
