import { ConfigLoaderService } from './../../../config/config-loader.service';
import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID, inject } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable, map } from 'rxjs';
import { CookieService } from 'ngx-cookie-service';
import { UrlService } from './url.service';
import { Product } from '@scalefast/core';
import { StoreSpecificConfigService } from '@scalefast/config-angular';
import { ProductHelper } from '../helpers/product.helper';
import { GenericLogger } from '@scalefast/ecommerce-core';

/**
 * Service to handle POH specific functionalities
 * The POH is a specific promotions page accessed through a specific URL and controlled by a coupon/browser
 * Several aspects of the generic elements of the page are modified based on the POH status
 */
@Injectable({ providedIn: 'root' })
export class PohService {
  private isPohProduct$ = new BehaviorSubject<Product | undefined>(undefined);
  private pohFeeds = [];
  #logger = inject(GenericLogger);

  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    @Inject(DOCUMENT) private _doc: Document,
    private router: Router,
    private urlService: UrlService,
    private storeSpecificConfigService: StoreSpecificConfigService,
    private cookieService: CookieService,
  ) {
    this.pohFeeds = this.storeSpecificConfigService.get().pohFeeds ?? [];
  }

  checkCoupons() {
    if (isPlatformBrowser(this.platformId)) {
      const queryParams = this.router.routerState.root.snapshot.root.queryParams || {};
      const couponid = queryParams.couponid;
      const batchid = queryParams.batchid;

      this.isFrancePohPage().subscribe((isFrancePoh) => {
        //HOTFIX: This should be properly checked by making an API call to verify whether the values ​​are correct or not
        if (isFrancePoh && (!couponid || !batchid || couponid == 'undefined' || batchid == 'undefined')) {
          this.#logger.warn('Redirect to home page because of missing couponid or batchid');
          this.router.navigate(['/']);
        }
      });

      if (couponid && batchid) {
        const date = new Date();
        date.setTime(date.getTime() + 7 * 24 * 60 * 60 * 1000);

        this._doc.cookie = `campaignid=${couponid}; expires="${date.toUTCString()}"; path=/`;
        this._doc.cookie = `trackid=${batchid}; expires="${date.toUTCString()}"; path=/`;
      }
    }
  }

  setCurrentProduct(product?: Product): void {
    this.isPohProduct$.next(product);
  }

  isAPohProductPage(): Observable<boolean> {
    return this.isPohProduct$.pipe(map((product) => this.checkIfProductIsPoh(product)));
  }

  isAPohPage(isAdvantagePoh?: boolean): Observable<boolean> {
    return this.urlService.currentUrl$.pipe(map((url) => this.findPath(isAdvantagePoh, url)));
  }

  isSpainPohPage(): Observable<boolean> {
    return this.urlService.currentUrl$.pipe(map((url) => url.includes('cuponprofesionalesdentales')));
  }

  isFrancePohPage(): Observable<boolean> {
    return this.urlService.currentUrl$.pipe(map((url) => url.includes('poh_fr')));
  }

  checkIfProductIsPoh(product?: Product): boolean {
    let isPoh = false;

    if (!this.pohFeeds || !product) {
      return false;
    }

    if (product.v4ApiResponse?.feedID) {
      let productFeeds = product.v4ApiResponse.feedID.split(',') || [];
      productFeeds = productFeeds.map((number: any) => parseInt(number));

      isPoh = ProductHelper.checkIfIsInFeeds(productFeeds, this.pohFeeds);
    }

    return isPoh;
  }

  getUrl(): string {
    let url = this.storeSpecificConfigService.get().pohUrl;
    const couponId = this.cookieService.get('campaignid');
    const batchId = this.cookieService.get('trackid');
    if (couponId != '' && batchId != '') {
      url += '?couponid=' + couponId + '&batchid=' + batchId;
    }

    return url;
  }

  private findPath(isAdvantagePoh?: boolean, path?: string): boolean {
    if (!path) {
      return false;
    }

    const urls: string[] = ['poh-education'];

    if (isAdvantagePoh) {
      urls.push('cuponprofesionalesdentales');
      urls.push('poh_fr');
    }

    return urls.find((url) => path.includes(url)) != undefined;
  }
}
